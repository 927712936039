import { Component, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from '../../home/home.service';
import { HeaderModel } from '../../homev2/homev2.model';
import { ResultService, ResultDataService } from '../../result/result.service';
declare function uploadMapData(): any;
@Component({
  selector: 'app-homev3',
  templateUrl: './homev3.component.html',
  styleUrls: ['./homev3.component.scss']
})
export class Homev3Component implements OnInit {
  public model = new HeaderModel();
  activeStep = 'Location';
  selectedV = true;
  // email: string;
  token;
  mail;
  areaEvent;
  roofTypeData;
  numSurface;
  roofPitch;
  lead;
  data;
  hotWater;
  yearly;
  Heater;
  oldHeater;
  isVisible = true;
  mapCondition = false;
  showLoader = false;
  yearlyElectricAfterChange;
  i = 0;
  filtered;
  heaterType;
  headArr = [  {title: 'Location', selected: false},
    {title: 'Roof Area', selected: false},
    {title: 'House Type', selected: false},
    {title: 'Roof Pitch', selected: false},
    {title: 'Orientation', selected: false},
    {title: 'Average Consumption', selected: false}
    // {title: 'Motivation'}
  ];

  constructor(private homeService: HomeService,
              private toastr: ToastrService,
              private loader: NgxSpinnerService,
              private Zone: NgZone,
              private translate: TranslateService,
              private  resultService: ResultService,
              private resultDataService: ResultDataService,
              private router: Router
  ) {
    // @ts-ignore
    window?.angularComponentRef  = { component: this, zone: Zone };
    this.model = new HeaderModel();
  }
  translateLang(heading) {
    // console.log(this.translate.instant('heading.' + heading));
    return this.translate.instant('heading.' + heading);
    // return heading;

  }

  ngOnInit(): void {}
  stepChange(step) {
    if (this.headArr.some(item => item.title === step && item.selected)) {
      if (this.roofTypeData !== 'flat' || !['Orientation', 'Roof Pitch'].includes(step)) {
        this.activateStep(step);
        return this.activeStep = step;
      }
    }
  }
  receiveData(data): void {
    console.log(data.email, data.token, data.lead, data.location);
    this.model.email = data.email;
    this.model.token = data.token;
    this.model.leadId = data.lead;
    // this.model.longitude = data.lat;
    // console.log(this.model.longitude);
    this.model.location = data.location;
    console.log(this.model.roofPitch);
    console.log(this.model.email);
    console.log(this.model.token);
    console.log(this.model.leadId);
    console.log(this.model.location);
    console.log(this.model.roofType, this.model.roofPitch);
    // this.mail = this.model.email;
    // console.log(this.mail);
  }
  receivedAlignment(data) {
    this.model.alignment = data.alignAngle;
    console.log(this.model.roofType, this.model.roofPitch, this.model.alignment );
  }
  receivedPitch(data) {
    this.roofPitch = data.pitch;
    this.model.roofPitch = data.pitch;
    console.log(this.model.roofPitch);
  }
  selectedData(rdata) {
    this.roofTypeData = rdata.value.key;
    console.log(this.roofTypeData);
    this.model.roofType = rdata.value.key;
    console.log(rdata.twoSideOccupy);
    if (rdata.twoSideOccupy) {
      this.model.roofSurfaceNumber = 2;
    } else {
      this.model.roofSurfaceNumber = 1;
    }
    console.log(this.model.roofType);
    console.log(this.model.roofSurfaceNumber);
  }

  selectedDataHotWater(data) {
    this.model.hotWaterType = data.value;
    this.hotWater = this.model.hotWaterType;
    console.log(this.model.hotWaterType , 'model');
  }
  selectedHeater(data) {
    this.model.heaterType = data.value;
    this.heaterType = this.model.heaterType;
    console.log(this.model.heaterType, 'model');
  }

  updateroofArea() {
    this.loader.show()
    this.homeService.updateroofArea({
      email: this.model.email,
      lead_id: this.model.leadId,
      rooftype: this.roofTypeData,
      tilt: 0,
      azimuth: 0,
      numsurfaces: this.model.roofSurfaceNumber ? 2 : 1
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.uploadExtraInput1();
    }, error => {
      this.toastr.warning('Roof Area not updated Please try again', 'Oops');
    });
  }
  activateStep(step) {
    this.headArr.forEach(item => item.selected = false);
    this.headArr.some( item => {
      item.selected = true;
      return item.title === step;
    });
  }
  next(step) {
    // console.log(step, 'step');
    if (step === 'Location') {
      this.activeStep = 'Roof Area';
      this.isVisible = true;
      this.activateStep(step);
    } else if (step === 'Roof Area') {
      uploadMapData();
      if (!this.mapCondition) {
        this.activeStep = 'House Type';
        this.isVisible = true;
        this.activateStep(step);
      } else {
        this.mapCondition = false;
      }
    } else if (step === 'House Type') {
      if (this.roofTypeData === 'flat') {
        this.activeStep = 'Hot Water';
        this.updateroofArea();
        this.isVisible = true;
        this.activateStep('Hot Water');
      } else {
        this.activeStep = 'Roof Pitch';
        this.isVisible = true;
        this.activateStep(step);
      }
    }  else if (step === 'Roof Pitch') {
      this.activeStep = 'Orientation';
      this.isVisible = true;
      this.activateStep(step);
    } else if (step === 'Orientation') {
      this.activeStep = 'Average Consumption';
      this.isVisible = true;
      this.activateStep(step);
    } else if (step === 'Average Consumption') {
      this.isVisible = true;
      this.activateStep(step);
      this.showLoader = true;
    } else {
      this.isVisible = true;
    }

  }

  uploadExtraInput1() {
    this.loader.show();
    this.homeService.uploadExtraInput({
      email: this.model.email,
      lead_id: this.model.leadId,
      info: {
        NumPeople: 4,
        Heating: "EL",
        Water: "OL",
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      this.model.consumption.YearlyElecRequired = response.energy;
      this.yearlyElectricAfterChange = response.energy;
    }, );
  } 
  checkAreaError() {
    // this.loader.hide();
    this.mapCondition = true;
    return this.mapCondition;
  }

  receivedElecId(event) {
    console.log(event);
    this.model.electric = event.elecID;
  }


  // uploadExtraInput1(event) {
  //   console.log(' xtrainput 1')
  //   this.model.consumption = event.value;
  //   console.log(this.model.consumption, 'numpoeple');
  //   this.oldHeater = this.model.heaterType;
  //   if (this.model.consumption) {
  //   this.homeService.uploadExtraInput({
  //     email: this.model.email,
  //     lead_id: this.model.leadId,
  //     info: {
  //       NumPeople: 4,
  //       Heating: "EL",
  //       Water: "OL",
  //       Type: 'Residential',
  //     }
  //   }).subscribe(res => {
  //     const response = res as any;
  //     this.model.consumption.YearlyElecRequired = response.energy;
  //     this.yearlyElectricAfterChange = response.energy;
  //     console.log(this.model.consumption.YearlyElecRequired);
  //   }, error => {
  //     this.toastr.warning('Something went Wrong Please try again', 'Oops');
  //   });
  // }
  // }






  // calledFromOutside(index) {
  //   this.loader.hide();
  //   console.log('outside-------');
  //   // this.checkArea(0, this.areaEvent);
  // }
  // checkArea(index, event) {
  //   this.homeService.checkAreaLoaction({
  //     email: this.email,
  //     lead_id: this.model.leadId,
  //     check: 'area'
  //   }).subscribe(res => {
  //       const response = res as any;
  //       this.area = Math.round(response.AreafromMap);
  //       this.onSubmit(index, event);
  //       this.toastr.success('Roof area uploaded successfully');
  //
  //     }, error => {
  //       // this.toastr.warning('Please first draw the area on the map', 'Oops');
  //     }
  //   );
  // }



}