<section>
    <div class="container justify-content-center text-center">
      <div class="row mt-5 p-3">
        <div class="col "><span class="dtext">{{'Roof-pitch.header' | translate}}</span></div>
      </div>
      <div class="row pb-3 heading ">
        <div class="col "><span class="htext">
        {{'Roof-pitch.heading' | translate}}</span>
        </div>
      </div>
  
      <ul>
        <li>
          <input type="checkbox" id="myCheckbox1" (click)="selectPitch(0)" [checked]="roofPitch == 0"/>
          <label for="myCheckbox1"><div class="angle-view">
            <div class="angle-line zero"></div>
          </div>
            <div class="angle-value">
              0&#176;
            </div></label>
        </li>
        <li>
          <input type="checkbox" id="myCheckbox2" (click)="selectPitch(30)" [checked]="roofPitch == 30"/>
          <label for="myCheckbox2"> <div class="angle-view">
            <div class="angle-line thirty left"></div>
            <div class="angle-line thirty right"></div>
          </div>
            <div class="angle-value">
              30&#176;
            </div>
          </label>
        </li>
        <li>
          <input type="checkbox" id="myCheckbox3" (click)="selectPitch(45)" [checked]="roofPitch == 45"/>
          <label for="myCheckbox3">
            <div class="angle-view">
              <div class="angle-line fourty-five left"></div>
              <div class="angle-line fourty-five right"></div>
            </div>
            <div class="angle-value">
              45&#176;
            </div>
          </label>
        </li>
      </ul>
  
      <!--    <div class="row remove-space justify-content-center">-->
      <!--      <div class="col-4">-->
      <!--        <div class="pitch-selector" (click)="selectPitch(0)" [ngClass]="{'selected': roofPitch==0}">-->
      <!--          <div class="angle-view">-->
      <!--            <div class="angle-line zero"></div>-->
      <!--          </div>-->
      <!--          <div class="angle-value">-->
      <!--            0&#176;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
  
      <!--      <div class="col-4">-->
      <!--        <div class="pitch-selector" (click)="selectPitch(30)" [ngClass]="{'selected': roofPitch==30}">-->
      <!--          <div class="angle-view">-->
      <!--            <div class="angle-line thirty left"></div>-->
      <!--            <div class="angle-line thirty right"></div>-->
      <!--          </div>-->
      <!--          <div class="angle-value">-->
      <!--            30&#176;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
  
      <!--      <div class="col-4">-->
      <!--        <div class="pitch-selector" (click)="selectPitch(45)" [ngClass]="{'selected': roofPitch==45}">-->
      <!--          <div class="angle-view">-->
      <!--            <div class="angle-line fourty-five left"></div>-->
      <!--            <div class="angle-line fourty-five right"></div>-->
      <!--          </div>-->
      <!--          <div class="angle-value">-->
      <!--            45&#176;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->
  
  
      <!-- Search bar -->
      <div class="row mt-4">
        <div class="col">
          <input (keyup)="pitchCheck()" [(ngModel)]="roofPitch" class="form-control searchbar text-center" type="text" placeholder="{{'Roof-pitch.placeholder' | translate}}" aria-label="Search">
        </div>
      </div>
  
      <div class="mt-3" >
        <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="submitForm()">{{'Continue' | translate}}</button>
      </div>
  
  
    </div>
  
  </section>
  
  