import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { HomeService } from '../../home/home.service';
import { HeaderModel } from '../../homev2/homev2.model';

@Component({
  selector: 'app-orientationv3',
  templateUrl: './orientationv3.component.html',
  styleUrls: ['./orientationv3.component.scss']
})
export class Orientationv3Component implements OnInit {

  public model = new HeaderModel();
  public tickPlacement = 'none';
  public showButtons = false;
  data;
  searchLocation;
  email;
  leadId;
  token;
  accessToken;
  @Input() tilt;
  @Input() numSurface;
  @Input() roofTypeKey;
  alignAngle;
  dragX = 0;
  dragY = 0;
  isDraging = false;
  mapScreenShot;
  image: SafeHtml;
  types = [];
  @Input() alignment = 0;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() angle: EventEmitter<any> = new EventEmitter<any>();
  constructor(private homeService: HomeService,
              private toastr: ToastrService,
              private appConstants: Constants,
              private sanitizer: DomSanitizer) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string ;
    });
    this.appConstants.leadId.subscribe( value => {
      this.leadId = value as string;
    });
    this.appConstants.accessTokenPreProd.subscribe(value => {
      this.accessToken = value as string;
    });}

  ngOnInit(): void {this.screenshotRunner();
    this.types = [
      {
        key: 'gable',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img class="img-fluid "  src="./assets/roof-type-top-view/gal.png">')
      },
      {
        key: 'pyramid',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img class="img-fluid "  src="./assets/roof-type-top-view/pyramid.png">')

      },
      {
        key: 'hip',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img class="img-fluid "  src="./assets/roof-type-top-view/hip.png">')
      },
      {
        key: 'flat',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img class="img-fluid "  src="./assets/roof-type-top-view/shed.png">')
      },
      {
        key: 'shed',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img class="img-fluid "  src="./assets/roof-type-top-view/shed.png">')
      }
    ]
    ;
    this.types.forEach(item => {
      if (this.roofTypeKey === item.key) {
        this.image = item.iconSvg;
      }
    });
  }
  // @HostListener('window:resize', ['$event'])
  // getScreenSize(event?): any {
  //   this.innerWidth = window.innerWidth;
  // }
  getCenter(element) {
    const {left, top, width, height} = element.getBoundingClientRect();
    return {x: left + width / 2, y: top + height / 2};
  }

  changeRotation(evt) {
    // var img = $('.house-circle');
    const img = document.getElementById('house-circle');
    const pwBox = img.getBoundingClientRect();
    // var offset = img.offset();
    if (this.isDraging) {
      const center_x = (pwBox.left + pwBox.right) / 2;
      const center_y = (pwBox.top + pwBox.bottom) / 2;
      // var center_x = (offset.left) + (img.width() / 2);
      // var center_y = (offset.top) + (img.height() / 2);
      const mouse_x = evt.pageX;
      const mouse_y = evt.pageY;
      const radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
      const degree = (radians * (180 / Math.PI)) ;
      // var rotateCSS = 'rotate(' + (degree + 170) + 'deg)';
      this.alignment = Math.round(degree);
    }
  }
  changeRotationTouch(evt) {
    // var img = $('.house-circle');
    const img = document.getElementById('house-circle');
    const pwBox = img.getBoundingClientRect();
    // var offset = img.offset();
    if (this.isDraging) {
      const center_x = (pwBox.left + pwBox.right) / 2;
      const center_y = (pwBox.top + pwBox.bottom) / 2;
      // var center_x = (offset.left) + (img.width() / 2);
      // var center_y = (offset.top) + (img.height() / 2);
      const mouse_x = evt.touches[0].pageX;
      const mouse_y = evt.touches[0].pageY;
      const radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
      const degree = (radians * (180 / Math.PI)) ;
      // var rotateCSS = 'rotate(' + (degree + 170) + 'deg)';
      this.alignment = Math.round(degree);
    }
  }

  updateroofArea() {
    this.homeService.updateroofArea({
      email: this.email,
      lead_id: this.leadId,
      rooftype: this.roofTypeKey,
      tilt: this.tilt,
      azimuth: this.alignment,
      numsurfaces: this.numSurface ? 2 : 1
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      // console.log(this.data, 'roofArea');
    }, error => {
      this.toastr.warning('Roof Area not updated Please try again', 'Oops');
    });
  }
  endDrag(event) {
    this.isDraging = false;
  }

  startDrag(event) {
    this.isDraging = true;
  }
  screenshotRunner() {
    this.mapScreenShot = localStorage.getItem('mapScreenShotURL');
  }

  submitForm() {
    this.onSubmit.emit({
      value: 'Orientation'
    });
    this.angle.emit({
      alignAngle: this.alignment
    });
    // console.log(this.email, this.leadId);
    this.updateroofArea();
    // this.homeService.azimuth.next()

  }

}
