<section>
    <div class="justify-content-center text-center">
      <div class="row mt-5 p-3 mb-2">
        <div class="col">
          <span class="dtext">{{'Consumption.header' | translate}}</span>
        </div>
      </div>
      <div class="row heading mt-3  py-2">
        <div class="col">
          <span class="htext">{{'Consumption.statequestion' | translate}}</span>
        </div>
      </div>
      <div class="row mt-4 ">
        <div class="col d-flex justify-content-center">
          <button *ngIf="stateList.length < 1"  style="border: 2px solid #154A75; color: #154A75; background-color: ghostwhite"  class="btn btn-block btn-light text-center spinner-button w-25 searchbar" type="button" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <select *ngIf="stateList.length > 1" name="electricId" id="state" class="w-25 form-control text-center searchbar " (change)="changeStateTariffDetails()" [ngModelOptions]="{standalone: true}"  [(ngModel)]="selectedState" class="form-control">
            <option [ngValue]="undefined"  hidden selected>{{'Consumption.state' | translate}}</option>
            <!--        <option  *ngIf="!stateList" [value]="0" hidden >select state</option>-->
            <option *ngFor="let state of stateList" [value]="state">{{state}}</option>
          </select>
        </div>
      </div>
  
      <div class="row heading my-3 py-2">
        <div class="col">
          <span class="htext">{{'Consumption.question2' | translate}}</span>
        </div>
      </div>
      <div class="row mt-4 ">
        <div class="col">
          <input class="form-control text-center searchbar w-25" [(ngModel)]="consumption.monthlyEbill"  [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'Consumption.question2' | translate}}" aria-label="Search">
          <mat-slider class="w-25 mt-4"  thumbLabel
          [(ngModel)]="consumption.monthlyEbill"
                         [displayWith]="formatLabel"
                         tickInterval="1000"
                         step="1"
                         min="0"
                         max="1000 " aria-label="unit(s)"></mat-slider>
        </div>
      </div>
  
  
  
      <div class="mt-3">
        <button type="button" style="font-size: 17px" class="btn continue btn-secondary" (click)="submitForm()">{{'Continue' | translate}}</button>
      </div>
  
    </div>
  </section>
  