import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { ConsumptionModel } from '../../consumptionv2/Consumption.model';
import { HomeService } from '../../home/home.service';
import { Quotev2Component } from '../../quotev2/quotev2.component';
import { ResultService, ResultDataService } from '../../result/result.service';

@Component({
  selector: 'app-consumptionv3',
  templateUrl: './consumptionv3.component.html',
  styleUrls: ['./consumptionv3.component.scss']
})
export class Consumptionv3Component implements OnInit {

  @Output() elecId: EventEmitter<any> = new EventEmitter<any>();
  @Input() Heater: EventEmitter<any> = new EventEmitter<any>();
  @Input() HotWater: EventEmitter<any> = new EventEmitter<any>();
  @Input() yearly: string;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: ConsumptionModel;

  email;
  stateList = [];
  tariffList = [];
  electrics = [];
  selectedState;
  selectedTariff;
  selectedSupplier;
  leadId;
  electricId;
  getSupplier = false;
  rdata;
  data;
  ePrice;
  nm;
  pvTariff;
  ePriceObj: any;
  nmObj: any;
  pvTariffObj;
  showLoader = false;
  formatLabel(value: number) {
    // if (value >= 1000) {
    return value + ' kWh';

  }
  constructor( private homeService: HomeService,
               private appConstants: Constants,
               private  toastr: ToastrService,
               private modalService: BsModalService,
               private bsModalRef: BsModalRef,
               private  resultService: ResultService,
               private router: Router,
               private resultDataService: ResultDataService,
               private loader: NgxSpinnerService,

  ) {  this.appConstants.email.subscribe(value => {
    this.email = value as string; });
       this.appConstants.leadId.subscribe( value => {
      this.leadId = value as string; });
       this.consumption = new ConsumptionModel();
       this.getSupplier = false;
       this.consumption.numPeople = 2;
  }

  ngOnInit(): void {
    this.getState();
    this.uploadExtraInput1();
  }
  // this is the first api that runs that will give us the tariffList

  ngOnChanges(changes: SimpleChanges) {
    if (this.leadId && this.leadId !== '' && this.yearly && this.yearly !== '') {
      this.getState();
    }
  }

  getState() {
    this.loader.show();
    this.homeService.getState(this.leadId, 'eprice').subscribe(res => {
      const response = res as any;
      if (res) {
        this.loader.hide();
      }
      for (const state of response.states ) {
        this.stateList.push(state);
      }
      this.ePriceObj = {
        ...response.eprice
      };
      this.nmObj = {
        ...response.nm
      };
      this.pvTariffObj = {
        ...response.pvtariff
      };
      console.log(this.ePriceObj);
      this.stateList.sort();
    }, error => {
      this.toastr.error('State data not found', 'Oops');
    });
  }
  changeStateTariffDetails() {
    localStorage.setItem('state', this.selectedState);
    this.ePrice = this.ePriceObj[this.selectedState];
    this.nm = this.nmObj[this.selectedState];
    this.pvTariff = this.pvTariffObj[this.selectedState];
    this.electricId = 0;
    console.log(this.nm, this.pvTariff);
    this.changeEPrice(this.ePrice);
  }


  yearlyEnergyConsumption() {

    this.consumption.YearlyElecRequired = Math.round((Math.round(1097 * this.consumption.numPeople + 2614.1) * 0.6) / 12);

    // else {
    //   this.consumption.YearlyElecRequired = Math.round((Math.round(1097 * this.consumption.numPeople + 2614.1) * 0.6) / 12);
    // }
  }



  updateContactInformation() {
    const initialState = {
      title: 'Contact form',
      class: 'modal-xl'
    };
    this.bsModalRef = this.modalService.show(Quotev2Component, {initialState});


    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      // myData.data.customercontact.lead_id = this.model.leadId;
      this.homeService.updateContactInformations(myData.data).subscribe(reply => {
        const response = reply as any;
        this.bsModalRef.hide();
        this.toastr.success('Quotation upload successfully');
      }, error => {
        this.toastr.warning('Contact Information not updated Please try again', 'Oops');
      });
    };
  }
  uploadExtraInput1() {
    this.loader.show();
    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: 4,
        Heating: "EL",
        Water: "OL",
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      // console.log(response, this.model.consumption.YearlyElecRequired );
      this.consumption.YearlyElecRequired = response.energy;
      if (this.consumption.YearlyElecRequired && this.ePrice) {
        this.changeEPrice(this.ePrice);
      }
      // this.yearlyElectricAfterChange = response.energy;
      // console.log(this.model.consumption.YearlyElecRequired);

    }, );
  } 
  changeEPrice(selectedEPrice) {
    if (this.consumption.YearlyElecRequired && selectedEPrice) {
      this.consumption.monthlyEbill = Math.round((this.consumption.YearlyElecRequired / 12) * selectedEPrice);
    }
  }
  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['de/resultV2']);
      if (this.data.status) {
        this.resultDataService.setResult(this.data);
      }
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  uploadYearlyConsumption() {
    this.homeService.postStateTariffDetails(this.leadId, 'upload', {
      eprice: this.ePrice,
      state: this.selectedState,
      ebillmonthly: this.consumption.monthlyEbill,
      nm: this.nm
    }).subscribe( res => {
      const response = res as any;
      console.log(response);
      this.getResult();
    }, error => {
      this.toastr.warning('Yearly Electric not updated Please try again', 'Oops');
    });
  }
  submitForm() {
    // this.getResult();
    // this.updateContactInformation();
    console.log((this.consumption.numPeople && this.selectedState && this.consumption.monthlyEbill));
    if (this.consumption.numPeople && this.selectedState && this.consumption.monthlyEbill) {
      this.uploadYearlyConsumption();
      this.onSubmit.emit({
        value: 'Average Consumption'
      });
    } else {
      this.toastr.warning('Please enter the details first!');
    }
  }



}
